<template>
  <v-list-item
    class="d-flex flex-row justify-center pa-0 my-3 sidebar-button"
  >
    <v-btn
      color="primary"
      type="submit"
      :text="isTypeText"
      :disabled="item.disabled"
      block
      depressed
      @click.prevent="item.action()"
    >
      <v-icon left v-text="item.icon" />
      {{ $t(item.text) }}
    </v-btn>
  </v-list-item>
</template>

<script>
// type: outlined, text
export default {
  name: 'SidebarCustomButton',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isTypeText: ({ item }) => item.button?.type === 'text',
    buttonIcon: ({ item }) => item.button?.icon,
  },
}
</script>

<style scoped>
.sidebar-button::before {
    opacity: 0 !important;
}
</style>
